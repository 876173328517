<!--suppress XmlUnusedNamespaceDeclaration -->
<template>
  <img
    class="product-screenshot"
    alt="Screenshot of Product"
    src="@/assets/images/products/crypto-utils-screenshot.png"
  />
</template>

<script>
export default {
  name: "CryptoUtilsScreenshot"
}
</script>

<style scoped>
.product-screenshot {
  max-width: 100%;
  border-radius: 5px 5px 5px 5px;
}
</style>
